<template>
	<!-- 
    pdf-content-width should be defined by absolute value.
    Because the exported pdf should not be changed according to screen.
    Default A4 paper = 595pt width
  -->
	<div>
		<div>
			<vue-html2pdf
				:show-layout="true"
				:float-layout="false"
				:enable-download="true"
				:preview-modal="false"
				:paginate-elements-by-height="1400"
				:filename="fileName"
				:pdf-quality="2"
				:manual-pagination="true"
				pdf-format="a4"
				pdf-orientation="portrait"
				pdf-content-width="100%"
				@hasStartedGeneration="hasStartedGeneration()"
				@hasGenerated="hasGenerated($event)"
				ref="html2Pdf"
			>
				<section id="section-layout" slot="pdf-content">
					<div :class="isDisplay">
						<!-- PDF Content Here -->
						<component
							ref="pdfViewer"
							:is="template"
							:pdfDataItems="pdfDataItems"
							:preview="true"
						></component>
						<component
							v-show="false"
							ref="pdfViewer"
							:preview="false"
							:is="template"
							:id="`pdf-viewer-${templateName}`"
							:pdfDataItems="pdfDataItems"
						></component>
					</div>
				</section>
			</vue-html2pdf>
		</div>
	</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { mapState } from 'vuex';

const DEFAULT_ITEMS = {
	expiryDate: '',
	issueDate: '',
	estimateDetails: [],
	estimateParticular: {},
};

export default {
	name: 'HxPdfExporter',
	props: {
		//TODO: Dummy
		templateName: {
			type: String,
			required: true,
		},
		fileName: {
			type: String,
			required: false,
			default: 'template',
		},
		isDisplay: {
			type: Boolean,
			required: false,
			default: false,
		},
		pdfData: {
			type: Object,
		},
	},
	data: function () {
		return {
			isGenerating: false,
			pdfDataItems: DEFAULT_ITEMS,
			sealRendered: false,
		};
	},
	computed: {
		...mapState(['creatorInfo']),

		template: function () {
			if (this.templateName) {
				return () => import(`./templates/${this.templateName}`);
			}
			return null;
		},
	},
	components: {
		VueHtml2pdf,
	},
	watch: {
		pdfData: {
			handler: function (after) {
				let pdfData = { ...after };
				this.pdfDataItems = this._filterPdfData(pdfData);
				console.log('[HxExporter] WATCH', this.pdfDataItems);
			},
			deep: true,
		},
	},
	methods: {
		generateReport() {
			this.$refs.html2Pdf.generatePdf();
		},

		hasStartedGeneration() {
			this.isGenerating = true;
		},

		hasGenerated($event) {
			console.log($event);
			this.isGenerating = false;
		},
		triggerPreview() {
			if (this.isDisplay == 'hidden') {
				this.isDisplay = 'visible';
			} else {
				this.isDisplay = 'hidden';
			}
		},

		getRenderedPDFTemplate(fileName) {
			let ele = this.templateString;

			// Break page base on css propertie: break-inside
			// https://ekoopmans.github.io/html2pdf.js/#page-breaks
			let opt = {
				margin: [0.4, 0, 0.4, 0],
				filename: fileName,
				pagebreak: { mode: ['css'] },
				image: { type: 'jpeg', quality: 2 },
				html2canvas: { scale: 2 },
				jsPDF: {
					unit: 'in',
					format: 'a4',
					orientation: 'portrait',
				},
			};
			return html2pdf()
				.from(ele)
				.set(opt)
				.toPdf()
				.get('pdf')
				.then((pdf) => {
					let total_page = pdf.internal.getNumberOfPages();
					try {
						for (let i = total_page; i > 0; i--) {
							pdf.setPage(i);
							pdf.setFontSize(12);
							pdf.text(i.toString(), 7.8, 11.3);
						}
					} catch (exception) {
						console.log(exception);
					}
				})
				.outputPdf('datauristring');
		},

		_filterDate(date) {
			if (date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `${year} 年 ${month} 月 ${day} 日`;
			} else {
				return '';
			}
		},

		_filterPdfData(pdfData) {
			let pdfDataItems = { ...pdfData };
			let issueDate = pdfData.estimateParticular['issue_date'];
			pdfDataItems.issueDate = this._filterDate(issueDate);
			let expiryDate = pdfData.estimateParticular['expiry_date'];
			pdfDataItems.expiryDate = this._filterDate(expiryDate);
			if (pdfData.estimateParticular['account_number']) {
				this.pdfDataItems.accountNumber =
					pdfData.estimateParticular['account_number'].toString();
			}
			return pdfDataItems;
		},

		_renderTemplateString() {
			let pfdViewer = document.getElementById(`pdf-viewer-${this.templateName}`);
			if (Object.keys(this.pdfData).length > 0 && pfdViewer) {
				this.templateString = pfdViewer.innerHTML;
				this.$emit('on-finished-render', true);
			}
		},
	},

	mounted() {
		if (Object.keys(this.pdfData).length > 0) {
			this.pdfDataItems = this._filterPdfData(this.pdfData);
		}
	},
};
</script>

<style src="./HxPdfExporter.css" type="text/css" scoped></style>
